/// <reference types="vite/client" />
import { ColorMode, LanguageCode, makeId, PlanCurrencyKind } from "shared";
import { getItem } from "./util/local-storage";
import { isMobile } from "./util/util";

export const IS_LOCAL_HOST = location.host.startsWith("localhost") || location.hostname === "ideanote-teams.test";
export type Environment = "local" | "production" | "staging" | "development";
// TODO: use "env.devserver" instead of this solution
export const ENVIRONMENT: Environment = IS_LOCAL_HOST
	? "local"
	: ((import.meta.env.MODE ?? "development") as Environment);
export const APP_BUILD_DATE = new Date(Number(import.meta.env.WEBAPP_BUILD_TIMESTAMP) || Date.now());
export const STATIC_ASSETS_PATH = "https://storage.googleapis.com/ideamap/static"; // TODO: Move to .env
// TODO: Remove getVerisonTag()
export function getVersionTag() {
	return `${APP_BUILD_DATE.toLocaleString()}`;
}
export const TEAMS_APP_ID = "da0ceaa6-d4a5-4982-a67f-2557630c02e5";
export const SERVICE_WORKER_ENABLED = !IS_LOCAL_HOST;
export const IS_DEBUGGING = ENVIRONMENT !== "production";
export const DPO_EMAIL = "dpo@ideamap.ai";
export const SUPPORT_EMAIL = "hello@ideamap.ai";
export const SERVICE_WORKER_PATH = "/sw.js";
export const SENTRY_KEY = "9b521590bff9b83f035fa7e51cdf6441@o702376";
export const SENTRY_PROJECT_ID = "4505912745394176";
export const SESSION_ID = makeId();

// TODO: Move these to .env
export const UMAMI_PLAYGROUND_ID = "44b19fe9-731b-4071-b97f-8e934a87831e";
export const UMAMI_LANDING_ID = "702927fc-cc98-40f9-b95b-3dc4dd4a703f";
export const UMAMI_WEBAPP_ID = "300adf5b-8200-464b-b230-25515bbc1a0d";

export const IDEA_WIDGET_IDS = {
	survey: "e9af2c36b9",
	feedback: "a66e46f8af"
};

export const SURVEY_USER_CREATED_AT_THRESHOLD_DAYS = 30;
export const SURVEY_ALERT_INTERVAL_DAYS = 60;
export const SURVEY_CHANCE = 0.1;

export const UPSELL_USER_CREATED_AT_THRESHOLD_DAYS = 14;
export const UPSELL_ALERT_INTERVAL_DAYS = 30;
export const UPSELL_ALERT_CHANCE = 0.4;

export const ROUTES = {
	index: "/",
	home: "/home",
	sitemap: "/sitemap",
	toolbox: (id?: string) => `/toolbox${id ? `/${id}` : ""}`,
	affiliate: "/affiliate",
	createRoom: "/create",
	help: (section?: string, content?: string) => `/help${section ? `/${section}${content ? `/${content}` : ""}` : ""}`,
	createWorkspace: "/setup",
	debugMindmap: "/debug/mindmap",
	debugUI: "/debug/ui",
	poster: "/poster",
	pdf: "/pdf",
	legal: "/legal",
	blog: (id?: string) => `/blog${id ? `/${id}` : ""}`,
	getStarted: "/get-started",
	contactSales: "/contact-sales",
	features: (id?: string) => `/features${id ? `/${id}` : ""}`,
	brainstormEmbed: "/brainstorm/embed",
	mindmapEmbed: "/mindmap/embed",
	brainstorm: "/brainstorm",
	generateMindmap: "/generate-ai-mindmap",
	setupTab: "/setup-tab",
	room: (slug: string) => `/room/${slug}`,
	joinRoom: (accessCode: string) => `/join/${accessCode}`,
	joinWorkspace: (accessCode: string) => `/invitation/${accessCode}`,
	pricing: "/pricing",
	gift: `/gift`,
	enterprise: `/enterprise`
};

export const DEFAULT_AVATAR_SIZE = 32;

export const QUERY_PARAMS = {
	embed: "embed",
	continue: "continue",
	utmMedium: "utm_medium",
	welcome: "welcome",

	// The "via" thing is required by promotokit
	affiliateVia: "via"
};

export const UTM_MEDIUM_PWA = "pwa";

export const MS_TEAMS_ENTITY_IDS = {
	home: "home"
};

export const ROUTE_CHANGED_EVENT = "route-changed";

export const MAX_IDEA_TEXT_LINES_BEFORE_TRUNCATE = 1;
export const MAX_IDEA_TEXT_CHARS_BEFORE_TRUNCATE = 400;

// Env.gcloudAssetPath

export const ASSETS_PATHS = {
	base: "/assets",
	legal: "/assets/legal",
	cover: "/assets/cover",
	copilots: "/assets/copilots",
	brand: "/assets/brand",
	helpDocs: (code: LanguageCode) => `/i18n/${code}/help-docs`,
	toolbox: (code: LanguageCode) => `/i18n/${code}/toolbox`,
	emojis: "/assets/emojis",
	aiModels: "/assets/ai-models",
	blog: `${STATIC_ASSETS_PATH}/blog`,
	music: `${STATIC_ASSETS_PATH}/music`
};
export const MAX_ROOM_COVER_COUNT = 17;
export const DEFAULT_ROOM_COVER_URL = getCover(0);
export const DEFAULT_ROBOT_AVATAR_URL = `${ASSETS_PATHS.copilots}/default.jpg`;

export const STORAGE_KEYS = {
	user: (roomId: string) => `user-${roomId}`,
	roomConclusion: (roomId: string) => `room-conclusion-${roomId}`,
	isMenuOpen: "is-menu-open",
	sessionUser: "session-user",
	colorMode: "color-mode",
	currentWorkspace: "current-workspace",
	lastExportedPDFElements: "last-exported-pdf-elements",
	askedSurveyDate: "survey-date",
	affiliateReferralId: "affiliate-referral-id",
	language: "language"
};

export const DEFAULT_COLOR_MODE: ColorMode = "light";

export const MAX_VISIBLE_USERS_IN_ROOM = 6;

export const MAX_VISIBLE_USERS_IN_ROOM_CARD = 3;

export const API_BASE_URL =
	location.hostname === "localhost" || location.hostname.startsWith("192.") || location.hostname.includes(".ngrok")
		? `/api`
		: import.meta.env.SHARED_API_EXTERNAL_ORIGIN;

export const API_BASE_WS_URL =
	location.hostname === "localhost" || location.hostname.startsWith("192.") || location.hostname.includes(".ngrok")
		? `wss://${location.host}`
		: import.meta.env.SHARED_API_WS_EXTERNAL_ORIGIN;

export const VOTING_RANK_SYMBOLS = ["👑", "🥈", "🥉"];

export const UNNAMED_USER_NAME = "Unnamed";
export const THUMBS_REACTIONS = ["👍", "👎"];
export const YES_NO_REACTIONS = ["✅", "⚠️", "❌"];
export const COLORED_HEARTS_REACTIONS = ["❤️", "💛", "💚", "💙", "💜", "🖤"];
export const EMOJI_FACE_REACTIONS = ["😃", "😂", "😍", "😬", "🤓", "😎", "🥳", "😭", "😱", "🤑"];
export const SWOT_REACTIONS = ["💪", "💔", "🔍", "🚩"];
export const RANKING_REACTIONS = ["🥇", "🥈", "🥉"];
export const HEATMAP_REACTIONS = ["🔥", "❄️"];
export const DOT_REACTIONS = ["🔴", "🟠", "🟡", "🟢", "🔵", "🟣"];
export const POSITIVE_REACTIONS = [
	"👍",
	"❤️",
	"😃",
	"😂",
	"🔥",
	"👑",
	"🌟",
	"🎉",
	"🎯",
	"🏆",
	"😍",
	"🤯",
	"👏",
	"🚀",
	"💯",
	"🌈",
	"💎"
];

export const EMOJI_FONT_NAME = "Noto Color Emoji";
export const DEFAULT_FONT_NAME = "Lato";

export const DEFAULT_TIMER_MINUTES = 2;
export const DEFAULT_TIMER_SECONDS = 0;

export const DEFAULT_VOTING_SYMBOL = "⭐️";

export const VOTING_SYMBOLS = [
	"⭐",
	"👍",
	"❤️",
	"🟢",
	"🟡",
	"🔵",
	"🟣",
	"🟠",
	"💡",
	"👏",
	"🚀",
	"💯",
	"🌈",
	"🔥",
	"🎯",
	"💎",
	"🏆",
	"😍",
	"👀",
	"🤯",
	"🎉",
	"👎"
];

export const DEFAULT_GROUP_STICKER = "📁";

export const REALTIME_USER_STATE_UPDATE_INTERVAL = 400;

export function getCover(n: number) {
	return `${ASSETS_PATHS.cover}/cover-${n}.jpg`;
}

export function getRandomCover() {
	return getCover(Math.floor(Math.random() * (MAX_ROOM_COVER_COUNT - 1)));
}

export function getIsMenuOpenFromStorage() {
	const val = getItem(STORAGE_KEYS.isMenuOpen);
	return val == null ? !isMobile() : Boolean(getItem(STORAGE_KEYS.isMenuOpen));
}

export const AI_ACTION_CONTAINER_GROUPS = {
	room: "room"
};

export function getPlanEmojiPath({ name, gif }: { name: string; gif?: boolean }) {
	return `${ASSETS_PATHS.emojis}/${name}.${gif ? "gif" : "svg"}`;
}

export const DEFAULT_CURRENCY: PlanCurrencyKind = "usd";

export const DEVICE_PIXEL_RATIO = typeof devicePixelRatio === "undefined" ? 1 : devicePixelRatio;
export const INTERCOM_APP_ID = "k2jnel0b";

export const DEFAULT_MAX_FILE_SIZE_BYTES = 1024 * 1024 * 20; // 20 MB
