import { api } from "../../api/api";
import { runAction } from "../action";
import {
	APIError,
	AuthCredentials,
	AuthCredentialsResponse,
	AuthProviderKind,
	EVENT_GUEST_SIGN_IN,
	EVENT_GUEST_SIGN_UP,
	EVENT_USER_SIGN_IN,
	EVENT_USER_SIGN_UP,
	hasValue,
	makeId,
	OAuthCredentials,
	User
} from "shared";
import { API_BASE_URL, ROUTES } from "../../config";
import { openAcceptTerms } from "../../dialogs/open-accept-terms";
import { teamsStore } from "../teams/teams-store";
import { takeFirst } from "../../util/observable-util";
import { loadScript } from "../../util/dom-util";
import { teamsService } from "../../services/teams-service";
import { pushState, reloadApp } from "../../util/route-util";
import { eventService } from "../../services/event-service";
import { sessionAction } from "./session-action";
import { languageStore } from "../language/language-store";

// https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple#3331292
interface AppleOauthResponse {
	authorization: {
		code: string;
		id_token: string;
		state: string;
	};
	user?: {
		email: string;
		name: {
			firstName: string;
			lastName: string;
		};
	};
}

export const refreshSessionUser = async () => runAction(sessionAction.refreshSessionUser, async () => api.user.me());

export const signup = (credentials: AuthCredentials, data?: Partial<User>) =>
	runAction(sessionAction.signup, async () => {
		const result = api.session.signup(credentials, data);

		// Track event before the session is set to get correct measurements
		await eventService.trackEvent(credentials.kind === "guest" ? EVENT_GUEST_SIGN_UP : EVENT_USER_SIGN_UP);

		return result;
	});

export const login = (credentials: AuthCredentials) =>
	runAction(sessionAction.login, async () => {
		const result = await api.session.login(credentials);

		// Track event before the session is set to get correct measurements
		await eventService.trackEvent(credentials.kind === "guest" ? EVENT_GUEST_SIGN_IN : EVENT_USER_SIGN_IN);

		return result;
	});

export const logout = ({ withoutNavigation }: { withoutNavigation?: boolean } = {}) =>
	runAction(sessionAction.logout, async () => {
		try {
			await api.session.logout();
		} catch (error) {}

		localStorage.clear();
		sessionStorage.clear();

		// We need to clear all stores. This is the most efficient way right now but we probably need another way.
		// In Teams, reloading the entire page might cause an endless loop login/logout because Teams automatically tries to log in the user.
		// Push state is used to prevent the page from reloading endlessly, but wont clear all stores properly.
		if (!withoutNavigation) {
			if (teamsStore.initialized) {
				pushState(ROUTES.index);
			} else {
				reloadApp(ROUTES.index);
			}
		}
	});

export function openWindow(url: string) {
	const width = Math.min(500, window.innerWidth);
	const height = Math.min(600, window.innerHeight);
	const top = window.screenY + (window.innerHeight - height) / 2;
	const left = window.screenX + (window.innerWidth - width) / 2;

	return window.open(
		encodeURI(url),
		undefined,
		`fullscreen=0${width != null ? `, width=${width}` : ""}${height != null ? `, height=${height}` : ""}${
			top != null ? `, top=${top}` : ""
		}${left != null ? `, left=${left}` : ""}`
	);
}

export function openAuthWindow(kind: AuthProviderKind): Promise<AuthCredentials> {
	const state = makeId();
	const params = { state };
	const baseUrl = `${API_BASE_URL}/auth/initiate/${kind}`;
	const url = `${baseUrl}?${new URLSearchParams(params)}`;

	if (teamsStore.initialized) {
		return new Promise(async (res, rej) => {
			try {
				const result = JSON.parse(
					await teamsService.authenticate({
						url: encodeURI(url)
					})
				) as AuthCredentialsResponse;

				if (result.success) {
					res(result.credentials);
				} else {
					rej(new Error(result.message || "Unknown error"));
				}
			} catch (err) {
				if (err.message === "CancelledByUser") {
					rej(new Error("popup_closed_by_user"));
				} else {
					rej(err);
				}
			}
		});
	}

	const authWindow = openWindow(url);

	if (!authWindow) throw new Error("Could not open auth window");

	return new Promise<AuthCredentials>((res, rej) => {
		let didReceiveMessage = false;

		const eventHandler = (event: MessageEvent<AuthCredentialsResponse>) => {
			// Only listen for messages with "success" in data
			const data = event.data;
			if (data == null || !("success" in data)) return;
			window.removeEventListener("message", eventHandler);

			didReceiveMessage = true;

			if (data.success) {
				const credentials = data.credentials;

				if ("state" in credentials && credentials.state !== state) {
					rej(new Error(`Invalid state: ${credentials.state} !== ${state}`));
				}

				if ("kind" in credentials && credentials.kind !== kind) {
					rej(new Error(`Invalid kind: ${credentials.kind} !== ${kind}`));
				}

				res(credentials);
			} else {
				rej(new Error(data.message || "Unknown error"));
			}

			authWindow.close();
		};

		window.addEventListener("message", eventHandler);

		// On close, remove listener
		const interval = setInterval(() => {
			if (authWindow.closed) {
				window.removeEventListener("message", eventHandler);
				clearInterval(interval);

				if (!didReceiveMessage) {
					rej(new Error("popup_closed_by_user"));
				}
			}
		}, 500);
	});
}

export const setCredentials = (credentials: AuthCredentials) =>
	runAction(sessionAction.setCredentials, async () => {
		api.session.setCredentials(credentials);
		return credentials;
	});

export type LoginOrSignupResult = "sign_up" | "sign_in";
export async function loginOrSignup(
	credentials: AuthCredentials,
	data?: Partial<User>
): Promise<LoginOrSignupResult | undefined> {
	let result: LoginOrSignupResult = "sign_in";
	try {
		await login(credentials);
	} catch (e) {
		if (e instanceof APIError && e.kind === "noAccountFound") {
			const acceptedTerms = await openAcceptTerms();
			if (!acceptedTerms) return undefined;

			await signup(credentials, data);
			result = "sign_up";
		} else {
			throw e;
		}
	}

	// Make sure to associate the teams auth token with the user to automatically assign the user to the correct workspace
	if (teamsStore.initialized) {
		const authToken = await takeFirst(teamsStore.model$.authToken);
		if (authToken != null) {
			await setCredentials({ kind: "teams", token: authToken });
		}
	}

	return result;
}

export function redirectOAuthFlow(kind: AuthProviderKind, { redirectUrl }: { redirectUrl: string }): void {
	const state = makeId();
	const params = { state, redirectUrl };
	const baseUrl = `${API_BASE_URL}/auth/initiate/${kind}`;
	const url = `${baseUrl}?${new URLSearchParams(params)}`;
	location.href = url;
}

export const openOAuthFlow = (kind: AuthProviderKind) =>
	runAction(sessionAction.oauth, async () => ({
		credentials: await openAuthWindow(kind),
		data: {
			data: {
				language: languageStore.currentLanguage
			}
		} as Partial<User>
	}));

/**
 * Opes the apple OAuth flow.
 * https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
 */
export const openAppleOAuthFlow = () =>
	runAction(sessionAction.oauth, async () => {
		// Load apples proprietary BS script (will add AppleID to window object).
		// The advantage of using this script instead of a popup is that it can open a native popup on iOS.
		const state = makeId();
		await loadScript("https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js").then();
		(window as any).AppleID.auth.init({
			clientId: "com.ideanote.ideamap.client",
			scope: "name email",
			state,
			redirectURI: `${location.origin}/auth/redirect/apple`,
			nonce: makeId(),
			usePopup: true
		});

		try {
			const data: AppleOauthResponse = await (window as any).AppleID.auth.signIn();
			console.log("Data received", data);

			const { code, state: receivedState } = data.authorization;
			const { firstName, lastName } = data.user?.name ?? {};

			return {
				credentials: {
					kind: "apple",
					code,
					state: receivedState
				} as OAuthCredentials,
				data: {
					name: [firstName, lastName].filter(hasValue).join(" ") || undefined,
					data: {
						language: languageStore.currentLanguage
					}
				} as Partial<User>
			};
		} catch (err) {
			console.error("Apple auth error", err);
			throw new Error(err.error || err.message || "Unknown error");
		}
	});
