// Idea
import { APIError } from "../types/api-error";
import { PollKind } from "../model/poll";
import { PlanKind } from "../types/plan";

function cleanEventName(name: string) {
	return name
		?.slice(0, 30)
		?.replace(/[ -]+/g, "_")
		?.replace(/[^a-zA-Z_]/g, "")
		?.toLowerCase();
}

function errorToEventName(error: Error) {
	return cleanEventName(error.message ?? error.name ?? "unknown");
}

function aiActionKindToEventName(kind: string) {
	return cleanEventName(`${kind.replace(/([A-Z])/g, ".$1").toLowerCase()}`);
}

export const EVENT_IDEA_CREATE = "idea.create";
export const EVENT_IDEA_REMOVE = "idea.remove";
export const EVENT_IDEA_COLLAPSE = "idea.collapse";
export const EVENT_IDEA_EXPAND = "idea.expand";
export const EVENT_IDEA_COLOR_UPDATE = "idea.color.update";
export const EVENT_IDEA_REACT = (reaction: string) => `idea.react.${reaction}`;
export const EVENT_IDEA_UPLOAD_SKETCH = "idea.upload.sketch";
export const EVENT_IDEA_UPLOAD_ATTACHMENT = "idea.upload.attachment";
export const EVENT_IDEA_SHARE = "idea.share";
export const EVENT_IDEA_COPY = "idea.copy";
export const EVENT_IDEA_INSPIRE_OPEN = "idea.inspire.open";
export const EVENT_IDEA_DETACH = "idea.detach";
export const EVENT_IDEA_ATTACH = "idea.attach";
export const EVENT_IDEA_GROUP_ON = "idea.group.on";
export const EVENT_IDEA_GROUP_OFF = "idea.group.off";
export const EVENT_IDEA_TURN_INTO_ROOM = "idea.turn_into_room";
export const EVENT_IDEA_FOLD = "idea.fold";
export const EVENT_IDEA_UNFOLD = "idea.unfold";

// Room
export const EVENT_ROOM_CREATE = "room.create";
export const EVENT_ROOM_UPDATE = "room.update";
export const EVENT_ROOM_REMOVE = "room.remove";
export const EVENT_ROOM_JOIN = "room.join";
export const EVENT_ROOM_LEAVE = "room.leave";
export const EVENT_ROOM_SHARE_OPEN = "room.share.open";
export const EVENT_ROOM_SHARE_OPTION_OPEN = (kind: string) => `room.share.option_${cleanEventName(kind)}.open`;
export const EVENT_ROOM_DETAILS_OPEN = "room.details.open";
export const EVENT_ROOM_EDIT_OPEN = "room.edit.open";
export const EVENT_ROOM_EXPORT_IDEAS_OPEN = "room.export_ideas.open";
export const EVENT_ROOM_MANAGE_PARTICIPANTS_OPEN = "room.manage.participants.open";

// User
export const EVENT_USER_CREATE = "user.create";
export const EVENT_USER_UPDATE = "user.update";
export const EVENT_USER_REMOVE = "user.remove";
export const EVENT_USER_SIGN_UP = "user.sign_up";
export const EVENT_USER_SIGN_IN = "user.sign_in";
export const EVENT_GUEST_SIGN_UP = "guest.sign_up";
export const EVENT_GUEST_SIGN_IN = "guest.sign_in";
export const EVENT_USER_DELETE_ACCOUNT = "user.delete_account";

// Cta
export const EVENT_CTA_GET_STARTED_CLICK = `cta.get_started.click`;
export const EVENT_CTA_SAVE_IDEAS_CLICK = `cta.save_ideas.click`;
export const EVENT_CTA_BRAINSTORM_CLICK = `cta.brainstorm.click`;
export const EVENT_CTA_BRAINSTORM_ERROR = `cta.brainstorm.error`;
export const EVENT_LANDING_ROOM_CREATE = `landing_room.create`;
export const EVENT_LANDING_ROOM_ERROR = `landing_room.error`;

// Workspace
export const EVENT_WORKSPACE_CREATE = "workspace.create";
export const EVENT_WORKSPACE_DELETE = "workspace.delete_account";

// Error
export const EVENT_UNHANDLED_ERROR = (err: Error | APIError) => `unhandled_error.${errorToEventName(err)}`;
export const EVENT_TEAMS_AUTH_TOKEN_ERROR = (err: Error) => `teams_auth_token_error.${errorToEventName(err)}`;

// AI
export const EVENT_AI_USE = (kind: string) => `ai_${aiActionKindToEventName(kind)}.use`;
export const EVENT_AI_ERROR = (kind: string) => `ai_${aiActionKindToEventName(kind)}.error`;
export const EVENT_AI_CONTENT_FILTER_TRIGGERED = (kind: string) =>
	`ai_${aiActionKindToEventName(kind)}.content_filter_triggered`;

// Poll
export const EVENT_POLL_CREATE = (kind: PollKind) => `poll.${kind}.create`;
export const EVENT_POLL_REMOVE = `poll.remove`;

// Billing and upgrade stuff
export const EVENT_FEATURE_OVERVIEW_OPEN = (feature?: string) =>
	`feature_overview${feature != null ? `.${feature}.` : ""}open`;
export const EVENT_FEATURE_WALL_OPEN = (feature?: string) =>
	`feature_wall${feature != null ? `.${cleanEventName(feature)}.` : ""}open`;
export const EVENT_PLAN_SELECTION_OPEN = `plan_selection.open`;
export const EVENT_PLAN_OPEN = (plan: PlanKind) => `plan.${plan}.open`;

// Timers
export const EVENT_TIMER_START = "tool.timer.start";
export const EVENT_TIMER_STOP = "tool.timer.stop";
export const EVENT_TIMER_PAUSE = "tool.timer.pause";

// Games
export const EVENT_GAME_OPEN = (game: string) => `game_${cleanEventName(game)}.open`;
export const EVENT_GAME_START = (game: string) => `game_${cleanEventName(game)}.start`;
export const EVENT_GAME_FINISH = (game: string) => `game_${cleanEventName(game)}.finish`;

// Toolbox
export const EVENT_TOOLBOX_OPEN = (id?: string) => `toolbox${id != null ? `_${cleanEventName(id)}` : ""}.open`;

// Music
export const EVENT_MUSIC_PLAY = (id: string) => `music.${cleanEventName(id)}.play`;

// Misc
export const EVENT_RATE_LIMITED_OPEN = "rate_limited.open";
export const EVENT_BOARD_CLEAN_UP = "board.clean_up";
export const EVENT_ONBOARDING_FINISH = "onboarding.finish";
export const EVENT_LIGHT_BOX_OPEN = "lightbox.open";
export const EVENT_MANAGE_OPEN = (tab?: string) => `manage${tab != null ? `.${cleanEventName(tab)}` : ""}.open`;
export const EVENT_SEARCH_OPEN = "search.open";
export const EVENT_TOOL_OPEN = (tool: string) => `tool.${tool}.open`;
export const EVENT_SURVEY_OPEN = "survey.open";
export const EVENT_UPSELL_OPEN = "upsell.open";
export const EVENT_CHANGELOG_OPEN = "changelog.open";
export const EVENT_LIVECHAT_OPEN = "livechat.open";
export const EVENT_KEYBOARD_SHORTCUTS_OPEN = "keyboard_shortcuts.open";
export const EVENT_CONTACT_SALES_OPEN = "contact_sales.open";

// Version history
export const EVENT_VERSION_HISTORY_OPEN = "version_history.open";
export const EVENT_RESTORE_VERSION = "version_history.restore";
